import React, { Component } from "react";
import { Link } from "react-router-dom";

import robotics from "../Pages/robotics"

class Robotics extends Component {

    render() {
        if (!this.props.data) return null;

        const about = "images/" + this.props.data.image;
        const bio = this.props.data.bio;

        return (
            <section id="services">
                <div className="row">
                    <h2 className="columns">Services</h2>
                </div>
                <div className="row card">
                    <div className="columns six card-block">
                        <img src="/images/X8.jpeg"></img>
                        <div className="card-content">
                            <h3>Robotics</h3>
                            <p> We build robots based on your needs, it maybe aerial, ground or underwater our team of Engineers will build it the as you want it to be. The remotely operated vehicles that we currently offer are based on payload capacity and range. To know more about our current offers please contact us via E-MAIL or via Phone. </p>
                        </div>
                    </div>
                    <div className="columns six card-block">
                        <img src="/images/CCTV.jpg"></img>
                        <div className="card-content">
                            <h3>Digital Surveillance System</h3>
                            <p>To have a 360 surveillance solutions our Expert team will scout your property and provide you with the best coverage and placements of CCTV cameras. We do property mangement, automated parking lots, night vision surveillance inside and outside property and database mangement server solutions. To know more about our current offers please contact us via E-MAIL or via Phone</p>
                        </div>
                    </div>
                </div>
                <div className="partners">
                    <div className="row">
                        <h2 className="columns">Partners</h2>
                    </div>
                    <div className="row">
                        <div className="columns five">
                            <img src="/images/AriDro_Logo.png"></img>
                        </div>
                        <div className="columns five">
                            <img src="/images/ad-zoo.png"></img>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Robotics;
