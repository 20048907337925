import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import ParticlesBg from "particles-bg";

class robotics extends Component {
    render() {
        return (
            <section >
                {/* <ParticlesBg num={3} type="circle" bg={true} /> */}
                <div className="contact-block">
                    <Fade bottom duration={1000}>
                        <h1>
                            <span>CONTACT US</span>
                        </h1>
                    </Fade>
                    <div className="row">
                        <Slide left duration={1000}>
                            <div className="eight columns">
                                <h2>Head Office</h2>
                                <p>PA,Traders,11/25</p>
                                <p>Kushaldoss Street</p>
                                <p>Chennai,Tamilnadu-600093</p>
                                <p>GSTIN :33BNRPG8818D1ZV</p>
                                <p>Phone : +919080105660</p>
                                <p>Email : Adlerdroness@gmail..com</p>
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>
        );
    }
}

export default robotics;
