import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const about = "images/" + this.props.data.image;
    const bio = this.props.data.bio;

    return (
      <section id="about">
        {/* <ParticlesBg num={3} type="circle" bg={true} /> */}
        <Fade duration={1000}>
          <div className="row">
            <div className="columns">
              <video className="about-video" src="/Videos/AD_video.mp4" autoPlay loop muted></video>
            </div>
            <div className="six columns">
              <img
                className="about-img"
                src={about}
                alt="Adler drones"
              />
            </div>
            <div className="six columns main-col">
              <h2>ABOUT US</h2>
              <p>{bio}</p>
              {/* <p>Our vision is to provide the best in class Rapid Response and Surveillance Drone Fleet to support special task forces like Police, firefighters, emergency services in fulfilling their duty to save and secure the public.firefighters and emergency services, who risk their life in saving others. Our Autonomous drones have successfully helped many task forces around the world by providing area surveillance, 3D mapping, scouting during missions, long range special cargo transport, Coastal surveillance, life guard deployment, etc.</p> */}
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
